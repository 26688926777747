/* ------------------------ Home Component Styling ---------------------------------- */

.home-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
}

.home-text {
    font-size: 2rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.home-subtext {
    font-size: 1.3rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

@media (max-width: 768px) {
    .home-text {
        font-size: 1.5rem;
    }

    .home-subtext {
        font-size: 1rem;
    }
}