.item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 75px;
  }
  
  .item-wrapper h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .item-wrapper h3 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .item-wrapper h4 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
  }
  
  .item-wrapper p {
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .item-wrapper a {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bolder;
    color: rgba(9, 60, 101, 0.9);
    text-decoration: none;
  }
  
  .item-wrapper a:hover {
    text-decoration: underline;
  }
  
  .back-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .back-icon {
    font-size: 24px; 
    color: var(--black);
    margin-top: 10px;
  }

  .back-icon:hover {
    color: turquoise;
  }

  .carousel-container {
    max-width: 400px;
    max-height: 400px;
    margin: 0 auto; /* Add this line to center horizontally */
  }