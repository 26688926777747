.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--white);
    padding-top: 5px;
}

.footer-wrapper {
    padding-bottom: 10px;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    border-top: 2px solid #ccc;
}

.footer-wrapper p {
    font-size: 16px;
}

ul.social-icons {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons li {
    list-style: none;
    margin: 0 10px;
}

.social-icons a {
    color: var(--black);
    text-decoration: none;
    font-size: 24px;
}

.fa-twitter {
    padding: 20px 10px;
    opacity: 0.9;
    transition: opacity 0.5s;
}

.fa-twitter:hover {
    color: #00aced;
    opacity: 0.95;
}

.fa-linkedin {
    padding: 20px 10px;
    opacity: 0.9;
    transition: opacity 0.5s;
}

.fa-linkedin:hover {
    color: #0073a4;
    opacity: 0.95;
}

.fa-github {
    padding: 20px 10px;
    opacity: 0.9;
    transition: opacity 0.5s;
}

.fa-github:hover {
    color: #5a32a3;
    opacity: 0.95;
}

@media (max-width: 1260px) {
    .footer-wrapper {
        width: calc(100% - 80px);
    }
}