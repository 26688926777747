.portfolio-menu-wrapper {
  margin-top: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
}

.link {
  display: block;
  margin: 10px;
  position: relative;
  text-decoration: none;
}

.portfolio-item {
  position: relative;
  display: inline-block;
}

.portfolio-img {
  width: 300px;
  height: 200px;
  object-fit: cover;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.link:hover .overlay {
  opacity: 1;
}

.overlay h3 {
  font-size: 18px;
  text-align: center;
}

@media (max-width: 600px) {
  /* Styles for small screens */
  .link:hover .overlay {
    opacity: 0;
  }

  .overlay {
    opacity: 1;
  }

}