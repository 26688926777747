.no-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.no-page-wrapper h2 {
    font-size: 24px;
    font-weight: normal;
    color: var(--black);
}

.no-page-info-wrapper p {
    font-size: 16px;
    font-weight: normal;
    color: var(--black);
}