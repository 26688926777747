.left,
.right {
    background: #ffffff;
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 20px;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.top,
.bottom {
    background: #ffffff;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    height: 20px;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

/* Media query for screens smaller than 600px (adjust the breakpoint as needed) */
@media (max-width: 600px) {
    .left,
    .right,
    .top,
    .bottom {
        display: none; /* Hide the page border on small screens */
    }
}
