@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

/* ------------------------ ROOT  ---------------------------------- */

body {
    font-family: 'Inconsolata', monospace;
    --white: #F2f2f2;
    --black: #222222;
    background: var(--white);
    margin: 0px 0px 0px 0px;
    height: 100%;
    overflow-x: hidden;
}