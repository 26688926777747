body h1 {
    position: fixed;
    top: 20px;
    left: 40px;
    z-index: 6;
    font-size: 32px;
    font-weight: 400;
    color: var(--black);
  }
  
  body h1 > span {
    text-transform: none;
    opacity: .5;
    font-weight: 300;
    font-size: 12px;
  }
  
  input + label {
    position: fixed;
    top: 50px;
    right: 40px;
    height: 20px;
    width: 15px;
    z-index: 5;
    cursor: pointer; /* Add this line */
  }
  
  input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: var(--black);
    transition: .5s;
  }
  
  input + label span:first-child {
    top: 3px;
  }
  
  input + label span:last-child {
    top: 16px;
  }
  
  label:hover {
    cursor: pointer;
  }
  
  input:checked + label span {
    opacity: 0;
    top: 50%;
  }
  
  input:checked + label span:first-child {
    opacity: 1;
    transform: rotate(405deg);
  }
  
  input:checked + label span:last-child {
    opacity: 1;
    transform: rotate(-405deg);
  }
  
  input ~ nav {
    background: var(--white);
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 3;
    transition: .3s;
    transition-delay: .2s;
    overflow: hidden;
  }
  
  input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 35%;
    left: 20%;
    right: 20%;
    list-style-type: none; /* Add this line */
  }
  
  input ~ nav > ul > li {
    opacity: 0;
    transition: 0.2s;
    transition-delay: 0s;
  }
  
  input ~ nav > ul > li > a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    padding: 30px;
    font-size: 24px;
    transition: color 0.2s;
    color: var(--black);
    letter-spacing: 2px; /* Add this line */
  }

  input ~ nav > ul > li > a:hover {
    color: turquoise; /* Add your desired color here */
  }
  input:checked ~ nav {
    height: 100%;
    transition-delay: 0s;
  }
  
  input:checked ~ nav > ul > li {
    opacity: 1;
    transition-delay: .5s;
  }
  